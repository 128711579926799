import { Splide, SlideComponent } from '@splidejs/splide';

// Sliders

// https://splidejs.com/guides/options/

const splide = new Splide('.module-carousel', {
    type: 'fade',
    width: '100vw',
    autoplay: true,
    interval: 5000,
    rewind: true,
    arrows: false,
    pagination: true
});

function pagerPosition(current?: SlideComponent) {
    const pagerElement = document.querySelector('.splide__pagination');

    if (pagerElement === null) return;

    const pager = pagerElement as HTMLElement;

    let active: HTMLElement;

    if (current == null) {
        const first = document.querySelector('.splide__slide#splide01-slide01 .banner-text');
        if (first === null) return;
        active = first as HTMLElement;
    }
    else {
        const currentElement = current.slide.querySelector('.banner-text');
        if (currentElement === null) return;
        active = currentElement as HTMLElement;
    }

    pager.style.top = (active.clientHeight + active.offsetTop) + "px";

}

splide.on('mounted', function () {
    pagerPosition();
})
splide.on('active', function (slide: SlideComponent) {
    pagerPosition(slide);
})

splide.mount();